.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center;
}

.login-background {
    min-height: 100vh;
    background: rgb(44,62,80);
    background: linear-gradient(135deg, rgba(44,62,80,1) 0%, rgba(37,116,169,1) 100%);
}